







import { Vue, Component, Prop } from 'vue-property-decorator'
import ReleaseStatus from '@/types/ReleaseStatus'
import { ReleaseStatus as ReleaseStatusApiEnum } from '@/api-client'

@Component
export default class ReleaseStatusChip extends Vue {
  @Prop({ required: true, type: Number }) value!: ReleaseStatusApiEnum

  get color() {
    return {
      [ReleaseStatus.Draft]: 'info',
      [ReleaseStatus.Allocated]: 'warning',
      [ReleaseStatus.Published]: 'accent-turquoise',
      [ReleaseStatus.OnDemandPurchasing]: 'accent-turquoise',
      [ReleaseStatus.Complete]: 'success',
      [ReleaseStatus.Cancelled]: 'error',
    }[this.value]
  }

  get text() {
    return ReleaseStatus[this.value]
  }
}
