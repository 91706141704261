export const scrollToFirstErrorTextElement = (offset = -100) => {
  const elementList = document.getElementsByClassName('error--text')
  if (elementList.length && elementList[0]) {
    const elementPosition = (elementList[0] as HTMLElement).getBoundingClientRect()
      .top
    const offsetPosition = elementPosition + window.pageYOffset + offset
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }
}
