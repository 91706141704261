

































import { ReleaseDTO, StatisticsDTO } from '@/api-client'
import { ordinal } from '@/modules/stringUtils'
import { ApexOptions } from 'apexcharts'
import { Vue, Component, Prop } from 'vue-property-decorator'
import ReleaseAllocationStatuses from '@/components/ReleaseAllocationStatuses.vue'
import { sortByMaybeNumberField } from '@/utils/sort'

interface CustomTooltipOptions {
  series: number[][]
  seriesIndex: number
  dataPointIndex: number
  w: {
    config: ApexOptions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    globals: any
  }
}

@Component({
  components: {
    ReleaseAllocationStatuses,
  },
})
export default class ReleaseStatistics extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  release!: ReleaseDTO

  statistics: StatisticsDTO | null = null

  get chartOptions() {
    return {
      colors: [
        this.$vuetify.theme.themes.light['accent-pine'],
        this.$vuetify.theme.themes.light['accent-turquoise'],
        this.$vuetify.theme.themes.light['primary-coral'],
        '#C6ECE4',
      ],
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
        width: '100%',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 0,
          horizontal: false,
        },
      },
      xaxis: {
        type: 'category',
        categories: this.chartCategories,
      },
      legend: {
        position: 'right',
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        custom: ({ series, dataPointIndex }: CustomTooltipOptions) => {
          return (
            '<div class="v-tooltip__content">' +
            series
              .map(
                (s: number[], i: number) =>
                  `<span class="pr-2">${this.chartData[i].name}:</span>` +
                  `<span>${s[dataPointIndex]}</span><br />`,
              )
              .join('') +
            '</div>'
          )
        },
      },
    }
  }

  get maxPreferences() {
    return this.statistics!.applications!.reduce(
      (acc, s) => Math.max(Object.keys(s.preferences!).length, acc),
      0,
    )
  }

  get chartCategories() {
    const categories = this.statistics!.applications!.map(s => s.lotName!)
    categories.sort(sortByMaybeNumberField)
    return categories
  }

  get chartData() {
    const applications = this.statistics!.applications!
    applications.sort((a, b) => sortByMaybeNumberField(a.lotName!, b.lotName!))
    return new Array(Math.min(this.maxPreferences, 3))
      .fill({
        name: '',
        data: [],
      })
      .map((_, i) => ({
        name: ordinal(i + 1) + ' preference',
        data: applications.map(s => s.preferences![i + 1] ?? 0),
      }))
      .concat([
        {
          name: 'Other preferences',
          data: applications.reduce((acc, s, i) => {
            Object.keys(s.preferences!)
              .map(k => parseInt(k))
              .forEach(k => (acc[i] += k > 3 ? s.preferences![k] : 0))
            return acc
          }, new Array(applications.length).fill(0)),
        },
      ])
  }

  get totalPreferences() {
    return this.statistics!.applications!.reduce(
      (acc, s) =>
        acc + Object.values(s.preferences!).reduce((acc, b) => acc + b, 0),
      0,
    )
  }

  get aggregateStats() {
    const mostPopularLot = this.statistics!.applications!.reduce(
      (acc, s, i) => {
        const [score, totalPreferences] = Object.entries(
          s.preferences!,
        ).reduce(
          (acc, [preference, amount]) => [
            acc[0] + (this.maxPreferences - parseInt(preference) + 1) * amount,
            acc[1] + amount,
          ],
          [0, 0],
        )
        return acc.score > score
          ? acc
          : {
              index: i,
              score,
              preferences: totalPreferences,
            }
      },
      { index: -1, score: 0, preferences: 0 },
    )
    return [
      {
        label: 'total applicants',
        value: this.release.numberOfApplications,
      },
      {
        label: 'total preferences',
        value: this.totalPreferences,
      },
      {
        label: 'most popular property',
        description: `${mostPopularLot.preferences} preferences submitted`,
        value:
          mostPopularLot.index === -1 || this.totalPreferences === 0
            ? 'N/A'
            : this.statistics!.applications![mostPopularLot.index]?.lotName ??
              'N/A',
      },
    ]
  }

  beforeMount() {
    this.$api.release
      .v1ReleasesReleaseIdStatisticsGet(this.release.id)
      .then(res => {
        this.statistics = res.data ?? null
      })
  }
}
