

















































































































































































































































































































































































































































import ReleaseDistributionDetails from '../ReleaseDistributionDetails.vue'
import TimePicker from '../inputs/TimePicker.vue'
import FileUpload from '../inputs/FileUpload.vue'
import ValidationMessages from '@/components/ValidationMessages.vue'
import { FileDTO, ReleaseDetailDTO, ReleaseDTO } from '@/api-client'
import { PropertyType } from '@/api-client'
import { add, format, isAfter, isFuture, isSameDay } from 'date-fns'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { parseUtcDate } from '@/modules/date'
import ReleaseStatus from '@/types/ReleaseStatus'
import { PRODUCT_NAME } from '@/modules/config'
import { scrollToFirstErrorTextElement } from '@/utils/scrollToView'
import * as Sentry from '@sentry/vue'
import { AxiosRequestConfig } from 'axios'
import { AustralianTimezones, DefaultTimezone } from '@/types/TimeZones'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    ValidationMessages,
    FileUpload,
    TimePicker,
    ReleaseDistributionDetails,
  },
  methods: {
    scrollToFirstErrorTextElement,
  },
})
export default class ReleaseDetailsForm extends Vue {
  propertyType: PropertyType | '' = ''
  @Prop({
    required: true,
    type: Object,
  })
  release!: ReleaseDTO
  @Prop({
    default: false,
    type: Boolean,
  })
  initAllocationPhase: boolean | undefined
  @Prop({
    default: false,
    type: Boolean,
  })
  initBuyNowPhase: boolean | undefined

  propertyTypes = Object.values(PropertyType)

  ReleaseStatus = ReleaseStatus

  timezones = AustralianTimezones

  PRODUCT_NAME = PRODUCT_NAME

  hasAttemptedSave = false
  loading = false
  inProgressFileUploads: Array<{
    url: string
    loaded: number
    total: number
  }> = []
  fileUploadProgress = 0
  fileUploadTotalSize = 0

  releaseDetails = {
    name: '',
    website: '',
    projectName: '',
    developerName: '',
    releaseIanaTimezone: DefaultTimezone,
    onDemandPurchasingEnabled: false,
    propertyType: '',
    skipAllocationsPhase: false,
    useProjectImageEmails: false,
    useDeveloperImageEmails: false,
    useDeveloperImageWeb: false,
  }
  sendRemainingLotsEmail = true

  showCloseDateMenu = false
  showBrandingLayout = false
  closeDate = ''
  closeTime = {
    HH: '',
    mm: '',
  }
  saveErrors: string[] = []

  get closeDatetime() {
    return new Date(
      `${this.closeDate}T${this.closeTime.HH}:${this.closeTime.mm}`,
    )
  }

  get isReleaseOnlyRelease() {
    return (
      this.releaseDetails.skipAllocationsPhase === false &&
      this.releaseDetails.onDemandPurchasingEnabled === false
    )
  }

  get minCloseDate() {
    return add(new Date(), { minutes: 5 })
  }

  get closeDateMinHour() {
    return isSameDay(this.closeDatetime, this.minCloseDate)
      ? this.minCloseDate.getHours()
      : 0
  }

  get closeDateMinMinute() {
    return isSameDay(this.closeDatetime, this.minCloseDate) &&
      this.closeDatetime.getHours() === this.closeDateMinHour
      ? this.minCloseDate.getMinutes()
      : 0
  }

  get allocationDatetime() {
    return new Date(
      `${this.allocationDate}T${this.allocationTime.HH}:${this.allocationTime.mm}`,
    )
  }

  showAllocationDateMenu = false
  allocationDate = ''
  allocationTime = {
    HH: '',
    mm: '',
  }

  get minAllocationDate() {
    return add(this.closeDatetime, { minutes: 5 })
  }

  get allocationDateMinHour() {
    return isSameDay(this.allocationDatetime, this.closeDatetime)
      ? this.minAllocationDate.getHours()
      : 0
  }

  get allocationDateMinMinute() {
    return isSameDay(this.allocationDatetime, this.closeDatetime)
      ? this.minAllocationDate.getMinutes()
      : 0
  }

  get isValidCloseDate() {
    return isFuture(this.closeDatetime)
  }

  get isValidAllocationDate() {
    return isAfter(this.allocationDatetime, this.closeDatetime)
  }

  projectImage: File | null = null
  projectImageSource = ''

  onProjectImageChange(newProjectImage: File | null) {
    if (newProjectImage) {
      const reader = new FileReader()
      reader.onload = () => {
        this.projectImageSource = reader.result as string
      }
      reader.readAsDataURL(newProjectImage)
    }
  }

  developerImage: File | null = null
  developerImageSource = ''

  onDeveloperImageChange(newDeveloperImage: File | null) {
    if (newDeveloperImage) {
      const reader = new FileReader()
      reader.onload = () => {
        this.developerImageSource = reader.result as string
      }
      reader.readAsDataURL(newDeveloperImage)
    }
  }

  documents: File[] = []
  documentNames: Record<string, string> = {}
  existingDocuments: Record<string, string> = {}

  updateDocuments(documents: FileDTO[]) {
    this.documents = documents.map(d => new File([], d.name))
    this.existingDocuments = documents.reduce(
      (acc, d) =>
        Object.assign(acc, {
          [d.name]: d.id,
        }),
      {},
    )
    for (const document of documents) {
      this.documentNames[document.name] = document.displayName
    }
  }

  updateDocumentNames() {
    this.$nextTick(() => {
      for (const document of this.documents) {
        if (!this.documentNames[document.name])
          this.$set(this.documentNames, document.name, document.name)
      }
    })
  }

  removeDocument(index: number) {
    const filename = this.documents[index].name
    delete this.documentNames[filename]
    this.$delete(this.existingDocuments, filename)
    this.$delete(this.documents, index)
  }

  get formatDetailsForPost() {
    return {
      ...this.releaseDetails,
      skipRemainingLotsEmail: !this.sendRemainingLotsEmail,
      propertyType: this.releaseDetails.propertyType,
      applicationsCloseDate: this.closeDatetime.toUTCString(),
      allocationDate: this.allocationDatetime.toUTCString(),
      website: /^(https?:\/\/)/.test(this.releaseDetails.website)
        ? this.releaseDetails.website
        : 'http://' + this.releaseDetails.website,
    } as ReleaseDetailDTO
  }

  get formatDocumentsForPost() {
    return this.documents.map(document => ({
      name: document.name,
      displayName: this.documentNames[document.name] ?? document.name,
    })) as FileDTO[]
  }

  get formatProjectImageForPost() {
    return this.projectImage
      ? ({
          name: this.projectImage.name,
          displayName: this.projectImage.name,
        } as FileDTO)
      : null
  }

  get formatDeveloperImageForPost() {
    return this.developerImage
      ? ({
          name: this.developerImage.name,
          displayName: this.developerImage.name,
        } as FileDTO)
      : null
  }

  get releaseUrlPretty() {
    return `${window.location.hostname}/r/${this.release.code}`
  }

  get releaseUrl() {
    return `${window.location.protocol}//${window.location.host}/r/${this.release.code}`
  }

  get releaseUrlRelative() {
    return `/r/${this.release.code}`
  }

  calculateFileUploadTotalSize() {
    return this.inProgressFileUploads.reduce((total, u) => {
      total += u.total
      return total
    }, 0)
  }

  calculateFileUploadProgress() {
    return this.inProgressFileUploads.reduce((progress, u) => {
      progress += u.loaded
      return progress
    }, 0)
  }

  recalculateUploadProgress() {
    this.fileUploadTotalSize = this.calculateFileUploadTotalSize()
    this.fileUploadProgress = this.calculateFileUploadProgress()
  }

  async save() {
    this.loading = true

    if (
      !this.releaseDetails.skipAllocationsPhase &&
      (!this.isValidCloseDate || !this.isValidAllocationDate)
    ) {
      this.hasAttemptedSave = true
      this.loading = false
      return
    }
    this.hasAttemptedSave = false

    let projectImageUrl = ''
    let developerImageUrl = ''
    const documentUrls: Record<string, string> = {}
    this.saveErrors = []
    this.inProgressFileUploads = []
    const documentIds: Record<string, string> = {}
    const filesToUpload: Record<string, File> = {}

    try {
      await this.$api.release.v1ReleasesReleaseIdDetailsPut(
        this.release.id,
        this.formatDetailsForPost,
      )

      for (const document of this.release.documents) {
        if (!this.existingDocuments[document.name]) {
          await this.$api.release.v1ReleasesReleaseIdDocumentsFileIdDelete(
            this.release.id,
            document.id!,
          )
        }
      }

      if (this.projectImage?.size) {
        const projectImageRespnose = await this.$api.release.v1ReleasesReleaseIdProjectImagePut(
          this.release.id,
          this.formatProjectImageForPost!,
        )

        projectImageUrl = projectImageRespnose.data.url!.split('?')[0]
        filesToUpload[projectImageRespnose.data!.url!] = this.projectImage
      }

      if (this.developerImage?.size) {
        const developerImageRespnose = await this.$api.release.v1ReleasesReleaseIdDeveloperImagePut(
          this.release.id,
          this.formatProjectImageForPost!,
        )

        developerImageUrl = developerImageRespnose.data.url!.split('?')[0]
        filesToUpload[developerImageRespnose.data!.url!] = this.developerImage
      }

      for (const document of this.formatDocumentsForPost) {
        if (!this.existingDocuments[document.name]) {
          const documentResponse = await this.$api.release.v1ReleasesReleaseIdDocumentsPost(
            this.release.id,
            document,
          )

          documentIds[document.name] = documentResponse.data.id!
          documentUrls[document.name] = documentResponse.data.url!.split('?')[0]
          filesToUpload[documentResponse.data!.url!] = this.documents.find(
            d => d.name === document.name,
          )!
        }
      }

      // upload all files
      const uploadObjects = Object.entries(filesToUpload)
      if (uploadObjects.length) {
        await Promise.all(
          uploadObjects.map(([url, file]) => {
            const uploadProgress = {
              url,
              loaded: 0,
              total: file.size,
            }
            this.inProgressFileUploads.push(uploadProgress)
            this.recalculateUploadProgress()
            const config: AxiosRequestConfig = {
              onUploadProgress: progressEvent => {
                uploadProgress.loaded = progressEvent.loaded
                this.recalculateUploadProgress()
              },
            }
            return this.$axios.put(url, file, config)
          }),
        )
      }

      const updatedDocuments = this.formatDocumentsForPost.map(d => ({
        ...d,
        url: documentUrls[d.name],
        id: documentIds[d.name] ?? this.existingDocuments[d.name],
      }))
      this.releaseDetails.website = this.formatDetailsForPost.website
      this.$emit('input', {
        details: this.formatDetailsForPost,
        projectImage: {
          ...this.formatProjectImageForPost,
          url: projectImageUrl,
        },
        developerImage: {
          ...this.formatDeveloperImageForPost,
          url: developerImageUrl,
        },
        documents: updatedDocuments,
      } as Partial<ReleaseDTO>)
      this.updateDocuments(updatedDocuments)
    } catch (err) {
      Sentry.captureException(err)
      this.saveErrors = ['An error occurred.']
    }

    this.inProgressFileUploads = []
    this.recalculateUploadProgress()
    this.loading = false
  }

  mounted() {
    this.releaseDetails.name = this.release.details?.name ?? ''
    this.releaseDetails.website = this.release.details?.website ?? ''
    this.releaseDetails.projectName = this.release.details?.projectName ?? ''
    this.releaseDetails.developerName =
      this.release.details?.developerName ?? ''
    this.releaseDetails.onDemandPurchasingEnabled =
      this.release.details?.onDemandPurchasingEnabled ??
      this.initBuyNowPhase === true
    this.releaseDetails.propertyType =
      this.release.details?.propertyType.toString() ?? ''
    this.releaseDetails.skipAllocationsPhase =
      this.release.details?.skipAllocationsPhase ??
      this.initAllocationPhase === false
    this.releaseDetails.useProjectImageEmails =
      this.release.details?.useProjectImageEmails ?? false
    this.releaseDetails.useDeveloperImageEmails =
      this.release.details?.useDeveloperImageEmails ?? false
    this.releaseDetails.useDeveloperImageWeb =
      this.release.details?.useDeveloperImageWeb ?? false
    this.sendRemainingLotsEmail = !(
      this.release.details?.skipRemainingLotsEmail ?? false
    )

    const closeDate = this.release.details?.applicationsCloseDate
      ? parseUtcDate(this.release.details?.applicationsCloseDate)
      : add(new Date(), { days: 1 })
    this.closeDate = format(closeDate, 'yyyy-MM-dd')
    this.closeTime = {
      HH: this.release.details?.applicationsCloseDate
        ? format(
            parseUtcDate(this.release.details?.applicationsCloseDate),
            'HH',
          )
        : '18',
      mm: this.release.details?.applicationsCloseDate
        ? format(
            parseUtcDate(this.release.details?.applicationsCloseDate),
            'mm',
          )
        : '00',
    }
    const allocationDate = this.release.details?.allocationDate
      ? parseUtcDate(this.release.details?.allocationDate)
      : add(new Date(), { days: 2 })
    this.allocationDate = format(allocationDate, 'yyyy-MM-dd')
    this.allocationTime = {
      HH: this.release.details?.allocationDate
        ? format(parseUtcDate(this.release.details?.allocationDate), 'HH')
        : '10',
      mm: this.release.details?.allocationDate
        ? format(parseUtcDate(this.release.details?.allocationDate), 'mm')
        : '00',
    }

    this.projectImage = this.release.projectImage
      ? new File([], this.release.projectImage.name)
      : null
    if (this.release.projectImage)
      this.projectImageSource = this.release.projectImage.url!
    this.updateDocuments(this.release.documents)

    this.developerImage = this.release.developerImage
      ? new File([], this.release.developerImage.name)
      : null
    if (this.release.developerImage)
      this.developerImageSource = this.release.developerImage.url!
    this.updateDocuments(this.release.documents)

    this.$nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (this.release.details?.name) (this.$refs.observer as any).validate()
    })
  }
}
