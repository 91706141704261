

















import { Vue, Component, Prop } from 'vue-property-decorator'
import download from '@/modules/download'

@Component
export default class DownloadButton extends Vue {
  @Prop({
    default: 'Download',
    type: String,
  })
  label!: string

  @Prop({
    type: String,
    required: true,
  })
  url!: string

  @Prop({
    type: String,
    default: '',
  })
  filename!: string

  @Prop({
    type: Boolean,
  })
  text!: boolean

  @Prop({
    type: Boolean,
  })
  block!: boolean

  @Prop({
    type: Boolean,
  })
  hideIcon!: boolean

  @Prop({
    type: String,
    default: '300px',
  })
  width!: string

  async download() {
    const errors: Error[] = await download(
      this.url,
      this.filename ? this.filename : [],
    )
    if (errors.length) this.$emit('error', errors[0])
  }
}
