import {
  loadStripe as loadStripeFromPublishableKey,
  Stripe,
} from '@stripe/stripe-js'

let stripe: Stripe | null = null

export default function loadStripe(): Promise<Stripe> {
  if (stripe) return Promise.resolve(stripe as Stripe)
  return loadStripeFromPublishableKey(
    process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY!,
  ).then(res => {
    stripe = res
    return res as Stripe
  })
}
