import { LotDTO, PropertyType } from '@/api-client'
import * as Sentry from '@sentry/vue'
import {
  ApartmentDataCsvHeader,
  csvHeadersToLotDTOMap,
  LotDataCsvHeader,
  optionalFields,
  TownhouseDataCsvHeader,
} from '@/types/Csv'

export const getCsvHeaders = (propertyType: PropertyType): string[] => {
  if (propertyType === PropertyType.Apartment) {
    return ApartmentDataCsvHeader
  } else if (propertyType === PropertyType.Lot) {
    return LotDataCsvHeader
  } else if (propertyType === PropertyType.Townhouse) {
    return TownhouseDataCsvHeader
  } else {
    Sentry.captureException(new Error('Unrecognised lot type ' + propertyType))
    return []
  }
}

export const validateLots = (propertyType: PropertyType, lotData: LotDTO[]) => {
  // this lists all the fields that should be set for the property type
  const csvHeaders = getCsvHeaders(propertyType)
  const errors = []

  for (let i = 0; i < lotData.length; i++) {
    const lot = lotData[i]

    for (const header of csvHeaders) {
      // use the map to resolve the field that should be set on the lot
      const field = csvHeadersToLotDTOMap[header]

      // check if the lot has the field. If it doesn't, and it's not an optional field, raise error
      if (!lot[field] && !optionalFields.includes(field)) {
        errors.push(`row ${i + 1}: missing ${header}`)
      }
    }
  }

  return errors
}
