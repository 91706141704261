export const chunk = <T>(inputArray: Array<T>, sizePerChunk: number): T[][] => {
  return inputArray.reduce<T[][]>((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / sizePerChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}
