export default async function download(
  urls: string | string[],
  filenames?: string | string[],
) {
  urls = Array.isArray(urls) ? urls : [urls]
  if (filenames) {
    filenames = Array.isArray(filenames) ? filenames : [filenames]
    if (filenames.length !== urls.length) {
      console.warn(
        `Download module: Number of urls (${urls.length}) and filenames (${filenames.length}) do not match. Reverting to default filenames.`,
      )
      filenames = undefined
    }
  }

  const errors: Error[] = []
  await Promise.all(
    urls.map((url, i) =>
      fetch(url)
        .then(resp => {
          if (!resp.ok)
            throw new Error(
              `Could not download file: ${resp.status} ${resp.statusText}`,
            )
          return resp.blob()
        })
        .then(blob => {
          const link = document.createElement('a')
          document.body.appendChild(link)
          link.style.display = 'none'
          link.href = window.URL.createObjectURL(blob)
          // the filename you want
          if (filenames) link.download = filenames[i]
          link.click()
          window.URL.revokeObjectURL(link.href)
          link.remove()
        })
        .catch(e => {
          errors.push(e)
        }),
    ),
  )

  return errors
}
