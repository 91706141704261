import { LotDTO } from '@/api-client'

export const ApartmentDataCsvHeader = [
  'apartment number',
  'bedrooms',
  'bathrooms',
  'car spaces',
  'area (sqm)',
  'price',
  'features',
]

export const LotDataCsvHeader = [
  'name',
  'price',
  'depth (m)',
  'width (m)',
  'area (sqm)',
  'lot details',
]

export const TownhouseDataCsvHeader = [
  'lot number',
  'land area (sqm)',
  'bedrooms',
  'bathrooms',
  'garage spaces',
  'building area (sqm)',
  'price',
  'features',
]

export const csvHeadersToLotDTOMap: Record<string, keyof Omit<LotDTO, 'id'>> = {
  name: 'name',
  'apartment number': 'name',
  'lot number': 'name',
  'lot details': 'description',
  features: 'description',
  price: 'price',
  'depth (m)': 'length',
  'width (m)': 'width',
  'area (sqm)': 'size',
  'land area (sqm)': 'landArea',
  'building area (sqm)': 'buildingArea',
  bedrooms: 'bedrooms',
  bathrooms: 'bathrooms',
  'car spaces': 'carSpaces',
  'garage spaces': 'carSpaces',
}

export const optionalFields = ['lot details', 'features']
