































import { Vue, Component, Prop } from 'vue-property-decorator'
import VueTimePicker from 'vue2-timepicker/src/vue-timepicker.vue'

interface Time {
  HH: string
  mm: string
}

@Component({
  components: { VueTimePicker },
})
export default class TimePicker extends Vue {
  @Prop({
    type: Boolean,
  })
  clearable!: boolean
  @Prop({
    type: Boolean,
  })
  disabled!: boolean
  @Prop({
    required: true,
    type: Object,
  })
  value!: Time
  @Prop({
    default: 0,
    type: Number,
  })
  minMinute!: number
  @Prop({
    default: 0,
    type: Number,
  })
  minHour!: number

  @Prop({
    default: () => [],
    type: Array,
  })
  errors!: string[]

  emit(eventData: { data: unknown }) {
    this.$emit('input', eventData.data as Time)
  }
}
