

























































































































































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import { PaymentSessionDTO, PropertyType, ReleaseDTO } from '@/api-client'
import { AxiosResponse } from 'axios'
import * as Sentry from '@sentry/vue'
import ReleaseStatus, { NonPreviewStatuses } from '@/types/ReleaseStatus'
import { SHOW_MESSAGE } from '@/store/snackbar'
import loadStripe from '@/modules/stripe'
import { formatAUD } from '@/modules/stringUtils'
import { parseUtcDate } from '@/modules/date'
import { CONTACT_EMAIL, PRODUCT_NAME } from '@/modules/config'
import download from '@/modules/download'

import DownloadButton from '@/components/inputs/DownloadButton.vue'
import BackButton from '@/components/BackButton.vue'
import ReleaseDetailsForm from '@/components/forms/ReleaseDetailsForm.vue'
import UploadLotDataForm from '@/components/forms/UploadLotDataForm.vue'
import SigningTimeAndDepositForm from '@/components/forms/SigningTimeAndDepositForm.vue'
import ReleaseDetails from '@/components/ReleaseDetails.vue'
import CopyButton from '@/components/CopyButton.vue'
import ValidationMessages from '@/components/ValidationMessages.vue'
import InfoCard from '@/components/InfoCard.vue'
import ProgressCentered from '@/components/ProgressCentered.vue'
import ReleaseTypeCard from '@/components/ReleaseTypeCard.vue'
import { validateLots } from '@/utils/lotCsv'

@Component({
  components: {
    InfoCard,
    ProgressCentered,
    ValidationMessages,
    CopyButton,
    ReleaseDetails,
    SigningTimeAndDepositForm,
    UploadLotDataForm,
    ReleaseDetailsForm,
    BackButton,
    DownloadButton,
    ReleaseTypeCard,
  },
  head: {
    title() {
      return {
        inner: 'New release',
      }
    },
    style: [
      {
        type: 'text/css',
        inner: `.v-main {
          background: url('/img/seller-background.svg');
          background-size: 650%;
          background-position: 5.5% 1.5%;
          background-attachment: fixed;
        }`,
      },
    ],
  },
})
export default class CreateRelease extends Vue {
  ReleaseStatus = ReleaseStatus
  NonPreviewStatuses = NonPreviewStatuses
  PRODUCT_NAME = PRODUCT_NAME
  CONTACT_EMAIL = CONTACT_EMAIL

  release: ReleaseDTO | null = null
  paymentSession: PaymentSessionDTO | null = null

  loading = true
  isConfirming = false
  expandedPanel?: -1 | 0 | 1 | 2 = 0
  showCancelDialog = false
  showHelpScreen = false
  publishErrors: string[] = []
  cancelErrors: string[] = []
  hasRetriedDownload = false
  invoiceOnly = false
  showCompleteDialog = false
  completionErrors: string[] = []
  releaseHasAllocationPhase = false
  releaseHasBuyNowPhase = false

  get releaseErrors() {
    const errors: string[] = []
    if (this.releaseHasAllocationPhase && this.invalidSigningTimes.length)
      errors.push(
        'Please enusure all contract appointment times are after the allocation time.',
      )
    if (
      this.releaseHasAllocationPhase &&
      this.release &&
      this.release.lots.length > this.release.signingTimes.length &&
      this.release.signingDetails
    )
      errors.push(
        'You must specify at least as many appointment times as there are properties.',
      )
    return errors
  }

  get invalidSigningTimes() {
    if (this.release?.signingTimes && this.release?.details) {
      const allocationDate = parseUtcDate(this.release.details.allocationDate)
      return this.release.signingTimes.filter(
        time => parseUtcDate(time.dateFrom!) < allocationDate,
      )
    } else {
      return []
    }
  }

  get isReleaseDetailsComplete() {
    if (this.release?.details?.applicationsCloseDate) {
      if (this.release?.details?.skipAllocationsPhase) {
        return true
      }
      return (
        parseUtcDate(this.release?.details?.applicationsCloseDate) > new Date()
      )
    }
    return false
  }

  get isSigningTimesComplete() {
    if (this.invalidSigningTimes.length) return false
    if (this.release) {
      return (
        this.release.signingDetails &&
        (!this.releaseHasAllocationPhase ||
          this.release.signingTimes.length >= this.release.lots.length)
      )
    }
    return false
  }

  get propertyType() {
    if (this.release?.details?.propertyType) {
      return this.release.details.propertyType as PropertyType
    }
    return ''
  }

  get isLotDataComplete() {
    return (
      this.release &&
      this.release.lots.length > 0 &&
      this.propertyType &&
      !validateLots(this.propertyType, this.release.lots).length
    )
  }

  get expansionPanelItems() {
    return [
      {
        title: 'Release details',
        status: this.isReleaseDetailsComplete ? 'completed' : 'incomplete',
      },
      {
        title: 'Property information',
        status: this.isLotDataComplete ? 'completed' : 'incomplete',
      },
      {
        title: this.releaseHasAllocationPhase
          ? 'Contract appointment time and deposit details'
          : 'Deposit details',
        status: this.invalidSigningTimes.length
          ? 'error'
          : this.isSigningTimesComplete
          ? 'completed'
          : 'incomplete',
      },
    ].map(item => ({
      ...item,
      color:
        item.status === 'error'
          ? 'error'
          : item.status === 'completed'
          ? 'success'
          : 'primary-coral',
    }))
  }

  get canPublish() {
    return (
      this.isReleaseDetailsComplete &&
      this.isSigningTimesComplete &&
      this.isLotDataComplete
    )
  }

  get formattedPricePerLot() {
    return !this.paymentSession
      ? ''
      : formatAUD(this.paymentSession.pricePerLot!, 1, true)
  }

  get formattedTotalPrice() {
    return !this.release || !this.paymentSession
      ? ''
      : formatAUD(
          this.release.lots.length * this.paymentSession.pricePerLot!,
          1,
          true,
        )
  }

  async getRelease() {
    await this.$api.release
      .v1ReleasesReleaseIdGet(this.$route.params.id)
      .then(res => {
        if (res.status === 200) {
          this.release = ((res as unknown) as AxiosResponse<ReleaseDTO>).data
          if (this.release.details) {
            this.releaseHasAllocationPhase = !this.release.details
              .skipAllocationsPhase
            this.releaseHasBuyNowPhase =
              this.release.details.onDemandPurchasingEnabled || false
          }
        } else {
          this.$router.push({
            path: '/error',
            params: {
              statusCode: res.status.toString(),
              message: `Release ${this.$route.params.id} does not exist`,
            },
          })
        }
      })
      .catch(err => {
        this.$router.push({
          path: '/error',
          params: { statusCode: '404', message: err.message },
        })
      })
  }

  beforeMount() {
    this.getRelease().then(() => {
      if (
        this.release &&
        !this.release.details &&
        !this.release.signingDetails &&
        !this.release.lots.length
      )
        this.showHelpScreen = true
      else if (this.$route.query.confirm) {
        this.$router.push(this.$route.path)
        if (
          this.release &&
          this.release.details &&
          this.release.signingDetails &&
          this.release.lots.length > 0
        ) {
          this.isConfirming = true
          this.prepareForPublish()
        }
      }
      this.loading = false
    })
  }

  onDetailsChange(release: Partial<ReleaseDTO>) {
    this.release!.details = release.details!
    this.release!.projectImage = release.projectImage
    this.release!.developerImage = release.developerImage
    this.release!.particularQuestions = release.particularQuestions!
    this.release!.documents = release.documents!
    this.expandedPanel = 1
    this.$store.commit(
      SHOW_MESSAGE,
      `${this.expansionPanelItems[0].title} saved`,
    )
  }

  onLotDataChange(release: Partial<ReleaseDTO>) {
    this.release!.lotsImportFileName = release.lotsImportFileName
    this.release!.lotsImportDate = release.lotsImportDate
    this.release!.lots = release.lots!
    this.expandedPanel = 2
    this.$store.commit(
      SHOW_MESSAGE,
      `${this.expansionPanelItems[1].title} saved`,
    )
  }

  onSigningTimesChange(release: Partial<ReleaseDTO>) {
    this.release!.signingDetails = release.signingDetails
    this.release!.signingTimes = release.signingTimes!
    this.expandedPanel = -1
    this.release!.particularQuestions = release.particularQuestions!
    this.$store.commit(
      SHOW_MESSAGE,
      `${this.expansionPanelItems[2].title} saved`,
    )
  }

  dismissHelpScreen(allocationPhase: boolean, buyNowPhase: boolean) {
    this.showHelpScreen = false
    this.releaseHasAllocationPhase = allocationPhase
    this.releaseHasBuyNowPhase = buyNowPhase
  }

  async onDownloadError(downloadId: string) {
    if (!this.hasRetriedDownload) {
      this.hasRetriedDownload = true
      await this.getRelease()
      const releaseDownload = this.release!.downloads!.find(
        d => d.id === downloadId,
      )!
      try {
        download(releaseDownload.url!, releaseDownload.name)
        this.hasRetriedDownload = false
      } catch (e) {
        Sentry.captureException(e, {
          extra: {
            filename: releaseDownload.name!,
            fileId: releaseDownload.id!,
          },
        })
        this.$store.dispatch(SHOW_MESSAGE, 'Could not download file.')
      }
    }
  }

  async prepareForPublish() {
    this.loading = true
    this.publishErrors = []
    window.scroll(0, 0)
    this.$api.release
      .v1ReleasesReleaseIdPublishPost(this.release!.id)
      .then(res => {
        if (res.data.paymentSession!.invoiceOnly) {
          this.invoiceOnly = res.data.paymentSession!.invoiceOnly
        }
        this.paymentSession = res.data.paymentSession!
        this.isConfirming = true
      })
      .catch(() => {
        this.publishErrors = ['An error occurred']
      })
    this.loading = false
  }

  async pay() {
    const stripe = await loadStripe()
    stripe
      .redirectToCheckout({
        sessionId: this.paymentSession?.stripeSessionId!,
      })
      .then(result => {
        if (result.error) {
          alert(result.error.message)
        }
      })
  }

  async invoice() {
    this.$api.release
      .v1ReleasesReleaseIdInvoicePost(this.release!.id)
      .then(() => {
        this.$router.push({
          path: `/releases/${this.release!.id}/published`,
          query: {
            invoiceOnly: 'true',
          },
        })
      })
  }

  async cancelRelease() {
    this.loading = true
    this.cancelErrors = []
    this.$api.release
      .v1ReleasesReleaseIdCancelPost(this.release!.id)
      .then(() => this.getRelease())
      .then(() => (this.showCancelDialog = false))
      .catch(() => {
        this.cancelErrors = ['An error occurred']
      })
    this.loading = false
  }

  async completeRelease() {
    this.loading = true
    this.completionErrors = []
    this.$api.release
      .v1ReleasesReleaseIdCompletePost(this.release!.id)
      .then(() => this.getRelease())
      .then(() => (this.showCompleteDialog = false))
      .catch(() => {
        this.completionErrors = ['An error occurred']
      })
    this.loading = false
  }
}
