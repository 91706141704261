const TimezoneLookup: { [index: string]: string } = {
  'Australia/Sydney': 'Australia/Melbourne',
  'Australia/Melbourne': 'Australia/Melbourne',
  'Australia/Canberra': 'Australia/Melbourne',
  'Australia/Victoria': 'Australia/Melbourne',
  'Australia/NSW': 'Australia/Melbourne',
  'Australia/ACT': 'Australia/Melbourne',
  'Australia/Hobart': 'Australia/Melbourne',
  'Australia/Tasmania': 'Australia/Melbourne',
  'Australia/Currie': 'Australia/Melbourne',
  'Australia/LHI': 'Australia/Melbourne',
  'Australia/Lord_Howe': 'Australia/Melbourne',

  'Australia/Brisbane': 'Australia/Brisbane',
  'Australia/Queensland': 'Australia/Brisbane',
  'Australia/Lindeman': 'Australia/Brisbane',

  'Australia/Adelaide': 'Australia/Adelaide',
  'Australia/Broken_Hill': 'Australia/Adelaide',
  'Australia/Yancowinna': 'Australia/Adelaide',

  'Australia/Darwin': 'Australia/Darwin',

  'Australia/Perth': 'Australia/Perth',
  'Australia/Eucla': 'Australia/Perth',
}

export const AustralianTimezones = [
  { value: 'Australia/Melbourne', text: 'Melbourne, Sydney, Canberra, Hobart' },
  { value: 'Australia/Brisbane', text: 'Brisbane' },
  { value: 'Australia/Adelaide', text: 'Adelaide, Broken Hill' },
  { value: 'Australia/Perth', text: 'Perth' },
  { value: 'Australia/Darwin', text: 'Darwin' },
]

const FallbackTimezone = 'Australia/Melbourne'

export const DefaultTimezone =
  TimezoneLookup[
    Intl.DateTimeFormat().resolvedOptions().timeZone || FallbackTimezone
  ] ?? TimezoneLookup[FallbackTimezone]
