import { AllocationStatus } from '@/api-client'

// Unfortunately Open API spec does not allow us to document enums
// So we have this file that needs to be kept up to date with API

// What the statuses mean:
// Pending -> Allocation not yet accepted/rejected by buyer
// Rejected -> Allocation was rejected
// Accepted -> Allocation was accepted, particulars are not complete and the window for completing particulars has not elapsed
// Complete -> Particulars were completed before and the window had elapsed
// Incomplete -> Particulars were not completed before and the window had elapsed
// Cancelled -> Sale was cancelled by the seller, as something fell through outside of Release.Me

type statusStrings =
  | 'Pending'
  | 'Rejected'
  | 'Accepted'
  | 'Complete'
  | 'Incomplete'
  | 'Cancelled'

const AllocationStatusCustom: Record<
  statusStrings | AllocationStatus,
  AllocationStatus | statusStrings
> = {
  Pending: 0,
  Rejected: 1,
  Accepted: 2,
  Complete: 3,
  Incomplete: 4,
  Cancelled: 5,
  0: 'Pending',
  1: 'Rejected',
  2: 'Accepted',
  3: 'Complete',
  4: 'Incomplete',
  5: 'Cancelled',
}

export default AllocationStatusCustom
