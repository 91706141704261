


































































































































































































































































































































































































































































import TimePicker from '../inputs/TimePicker.vue'
import ValidationMessages from '../ValidationMessages.vue'
import { formatSigningTime, parseUtcDate } from '@/modules/date'
import { add, differenceInMinutes, format, isSameDay } from 'date-fns'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ReleaseDTO,
  ReleaseSigningDetailDTO,
  ReleaseSigningTimeDTO,
  ParticularQuestionDTO,
} from '@/api-client'
import { ValidationProviderType } from '@/types/validation'
import {
  FinanceTimeframe,
  FinanceTimeframeDescription,
} from '@/types/FinanceTimeframe'
import { MARKETING_URL } from '@/modules/config'
import { scrollToFirstErrorTextElement } from '@/utils/scrollToView'
import { chunk } from '@/utils/array'
import * as Sentry from '@sentry/vue'

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    ValidationMessages,
    TimePicker,
  },
  methods: {
    scrollToFirstErrorTextElement,
  },
})
export default class SigningTimeAndDepositForm extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  release!: ReleaseDTO
  @Prop({
    default: false,
    type: Boolean,
  })
  initAllocationPhase: boolean | undefined

  MARKETING_URL = MARKETING_URL

  showSigningDateMenu = false
  signingDate = format(this.minSigningDate, 'yyyy-MM-dd')
  loading = false
  hasAttemptedAddSigningTimes = false

  startTime = {
    HH: '13',
    mm: '00',
  }
  endTime = {
    HH: '14',
    mm: '00',
  }

  saveErrors: string[] = []

  get signingDateFrom() {
    return new Date(
      `${this.signingDate}T${this.startTime.HH}:${this.startTime.mm}:00`,
    )
  }

  get signingDateTo() {
    const dateTo = new Date(
      `${this.signingDate}T${this.endTime.HH}:${this.endTime.mm}:00`,
    )
    const isAcrossDays = this.startTime.HH === '00' && this.endTime.HH === '23'

    return add(dateTo, { days: isAcrossDays ? 1 : 0 })
  }

  get minSigningDate() {
    return this.release.details?.allocationDate
      ? parseUtcDate(this.release.details?.allocationDate)
      : new Date()
  }

  get minSigningDateHour() {
    return isSameDay(this.signingDateFrom, this.minSigningDate)
      ? this.minSigningDate.getHours()
      : 0
  }

  get minSigningDateMinute() {
    return isSameDay(this.signingDateFrom, this.minSigningDate) &&
      this.signingDateFrom.getHours() === this.minSigningDateHour
      ? this.minSigningDate.getMinutes()
      : 0
  }

  get minEndSigningDateHour() {
    return differenceInMinutes(this.signingDateTo, this.signingDateFrom) < 30
      ? add(this.signingDateFrom, { minutes: 30 }).getHours()
      : 0
  }

  get minEndSigningDateMinute() {
    return differenceInMinutes(this.signingDateTo, this.signingDateFrom) < 30
      ? add(this.signingDateFrom, { minutes: 30 }).getMinutes()
      : 0
  }

  signingTimes: { dateFrom: Date; dateTo: Date }[] = []

  get signingTimesErrors() {
    const errors = []

    if (this.hasAttemptedAddSigningTimes) {
      if (this.signingDateFrom >= this.signingDateTo)
        errors.push('Start time must be before end time')

      const invalidSigningTimes = this.signingTimes.filter(
        time => time.dateFrom < this.minSigningDate,
      )
      if (invalidSigningTimes.length)
        errors.push(
          `Appointment times cannot be in the past or before the allocation date (marked red below)`,
        )
      else if (this.signingDateFrom < this.minSigningDate)
        errors.push(
          `Appointment times cannot be in the past or before the allocation date`,
        )
      if (this.signingTimes.length < this.release.lots.length)
        errors.push(
          `Must have at least as many appointment times as properties (${this.release.lots.length})`,
        )
    }

    return errors
  }

  signingDetails: ReleaseSigningDetailDTO = {
    depositAccountName: '',
    depositAccountBsb: '',
    depositAccountNumber: '',
    depositAmount: 1000,
    legalPractitionerEmail: '',
    salesConsultantName: '',
    salesConsultantPhoneNumber: '',
    salesConsultantEmail: '',
    financeTimeframe: FinanceTimeframe.UNCONDITIONAL,
    signingLocation: '',
    signingInstructions: '',
  }

  financeTimeframes = Object.entries(
    FinanceTimeframeDescription,
  ).map(([key, value]) => ({ text: value, value: parseInt(key) }))

  addSigningTimes() {
    this.hasAttemptedAddSigningTimes = true
    let dateFrom = this.signingDateFrom
    if (dateFrom < this.minSigningDate) return
    let signingTimeEnd = add(this.signingDateFrom, { minutes: 30 })
    while (signingTimeEnd <= this.signingDateTo) {
      this.signingTimes.push({
        dateFrom: dateFrom,
        dateTo: signingTimeEnd,
      })
      dateFrom = signingTimeEnd
      signingTimeEnd = add(dateFrom, { minutes: 30 })
    }
  }
  removeSigningTime(index: number) {
    this.$delete(this.signingTimes, index)
  }

  get formattedSigningTimes() {
    if (!this.signingTimes.length) return []
    return this.signingTimes
      .sort((a, b) =>
        a.dateFrom < b.dateFrom ? -1 : a.dateFrom > b.dateFrom ? 1 : 0,
      )
      .map(t => ({
        formatted: formatSigningTime(t.dateFrom!, t.dateTo!),
        valid: t.dateFrom! >= this.minSigningDate,
      }))
  }

  get formatSigningTimesForPost() {
    return this.signingTimes.map(t => ({
      dateFrom: t.dateFrom.toUTCString(),
      dateTo: t.dateTo.toUTCString(),
    })) as ReleaseSigningTimeDTO[]
  }

  get skipAllocationPhase() {
    if (this.release && this.release.details) {
      return this.release.details.skipAllocationsPhase
    }
    return this.initAllocationPhase === false
  }

  additionalQuestions: ParticularQuestionDTO[] = []
  questionId = 1

  addQuestion() {
    this.$set(this.additionalQuestions, this.additionalQuestions.length, {
      title: '',
      description: '',
      isRequired: undefined,
      id: this.questionId,
    })
    this.questionId += 1
  }

  removeQuestion(index: number) {
    this.$delete(this.additionalQuestions, index)
    this.$nextTick(() => {
      for (const question of this.additionalQuestions) {
        const vps = [
          ((this.$refs[
            `question-${question.id}-mandatory`
          ] as unknown) as ValidationProviderType[])[0],
          ((this.$refs[
            `question-${question.id}-title`
          ] as unknown) as ValidationProviderType[])[0],
        ]
        for (const vp of vps) {
          vp.setFlags({
            dirty: vp.flags.valid,
            passed: vp.flags.valid,
          })
        }
      }
    })
  }

  legalPractitionerEmailAddresses: { id: number; email: string }[] = [
    {
      id: 0,
      email: '',
    },
  ]
  legalPractitionerEmailAddressesId = 1

  addlegalPractitionerEmailAddress() {
    this.$set(
      this.legalPractitionerEmailAddresses,
      this.legalPractitionerEmailAddresses.length,
      {
        email: '',
        id: this.legalPractitionerEmailAddressesId,
      },
    )
    this.legalPractitionerEmailAddressesId += 1
  }

  removelegalPractitionerEmailAddress(index: number) {
    this.$delete(this.legalPractitionerEmailAddresses, index)
    this.$nextTick(() => {
      for (const email of this.legalPractitionerEmailAddresses) {
        const vps = [
          ((this.$refs[
            `email-${email.id}-email`
          ] as unknown) as ValidationProviderType[])[0],
        ]
        for (const vp of vps) {
          vp.setFlags({
            dirty: vp.flags.valid,
            passed: vp.flags.valid,
          })
        }
      }
    })
  }

  legalPractitionerEmailAddressesFromString(string: string) {
    const emails = string.split(',')
    this.legalPractitionerEmailAddressesId = emails.length
    const output = emails.map((email, i) => ({
      email: email,
      id: i,
    }))
    return output
  }

  get legalPractitionerEmailAddressesToString() {
    return this.legalPractitionerEmailAddresses.reduce(
      (acc, address) => `${acc}${acc === '' ? '' : ','}${address.email}`,
      '',
    )
  }

  async save() {
    this.loading = true
    this.saveErrors = []
    this.hasAttemptedAddSigningTimes = true

    this.signingDetails.legalPractitionerEmail = this.legalPractitionerEmailAddressesToString

    try {
      await this.$api.release.v1ReleasesReleaseIdSigningDetailsPut(
        this.release.id,
        this.signingDetails,
      )

      await this.$api.release.v1ReleasesReleaseIdSigningTimesStartPost(
        this.release.id,
      )

      for (const chunkOfSigningTimes of chunk(
        this.formatSigningTimesForPost,
        50,
      )) {
        await this.$api.release.v1ReleasesReleaseIdSigningTimesPost(
          this.release.id,
          {
            signingTimes: chunkOfSigningTimes,
          },
        )
      }

      await this.$api.release.v1ReleasesReleaseIdParticularQuestionsPut(
        this.release.id,
        this.additionalQuestions.map(question => ({
          title: question.title!,
          description: question.description!,
          isRequired: question.isRequired,
        })),
      )

      this.$emit('input', {
        signingDetails: this.signingDetails,
        signingTimes: this.formatSigningTimesForPost,
        particularQuestions: this.additionalQuestions,
      } as Partial<ReleaseDTO>)
    } catch (err) {
      Sentry.captureException(err)
      this.saveErrors = ['An error occurred.']
    }

    this.loading = false
  }

  mounted() {
    this.signingDetails = this.release.signingDetails ?? this.signingDetails
    this.signingTimes = this.release.signingTimes.map(t => ({
      dateFrom: parseUtcDate(t.dateFrom!),
      dateTo: parseUtcDate(t.dateTo!),
    }))

    this.$nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (this.release.signingDetails) (this.$refs.observer as any).validate()
    })

    this.additionalQuestions = this.release.particularQuestions ?? []
    this.signingDetails.financeTimeframe =
      this.release.signingDetails?.financeTimeframe ??
      FinanceTimeframe.UNCONDITIONAL
    if (this.release.signingDetails) {
      this.legalPractitionerEmailAddresses = this.legalPractitionerEmailAddressesFromString(
        this.release.signingDetails!.legalPractitionerEmail,
      )
    }
  }
}
