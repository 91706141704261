var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.allocations.length)?_c('v-expansion-panels',{staticClass:"mt-6",attrs:{"flat":""},model:{value:(_vm.expandedPanel),callback:function ($$v) {_vm.expandedPanel=$$v},expression:"expandedPanel"}},[_c('v-expansion-panel',{staticClass:"mt-4"},[_c('v-expansion-panel-header',{attrs:{"disable-icon-rotate":"","expand-icon":_vm.expandedPanel === 0 ? '$minus' : '$plus'}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',{staticClass:"text-h4 mr-4"},[_vm._v("Allocations")])])]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"pa-12 pb-6",attrs:{"disable-pagination":true,"headers":_vm.headers,"dense":"","items":_vm.transformedAllocations,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-4"},[_vm._v(_vm._s(item.fullName))]),_c('div',{staticStyle:{"width":"min-content"}},[_vm._v(_vm._s(item.emailAddress))]),_c('div',{staticClass:"mb-4",staticStyle:{"width":"min-content"}},[_vm._v(" "+_vm._s(item.phoneNumber)+" ")])]}},{key:"item.particularsComplete",fn:function(ref){
var item = ref.item;
return [(item.status !== 'Unallocated')?_c('div',{staticClass:"d-flex justify-space-between mx-4"},[(item.particularsComplete)?_c('v-icon',{attrs:{"color":"accent-turquoise"}},[_vm._v("$checkInnerCircleFilled")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("$crossCircle")])],1):_vm._e()]}},{key:"item.signingTimeComplete",fn:function(ref){
var item = ref.item;
return [(item.status !== 'Unallocated')?_c('div',{staticClass:"d-flex justify-center"},[(item.signingTimeComplete)?_c('v-icon',{attrs:{"color":"accent-turquoise"}},[_vm._v("$checkInnerCircleFilled")]):(item.allocationType == _vm.AllocationType.OnDemand)?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("$lineCircle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("$crossCircle")])],1):_vm._e()]}},{key:"item.depositComplete",fn:function(ref){
var item = ref.item;
return [(item.status !== 'Unallocated')?_c('div',{staticClass:"d-flex justify-center"},[(item.depositComplete)?_c('v-icon',{attrs:{"color":"accent-turquoise"}},[_vm._v("$checkInnerCircleFilled")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("$crossCircle")])],1):_vm._e()]}},{key:"item.downloads",fn:function(ref){
var item = ref.item;
return [(item.downloads.length)?_c('v-menu',{attrs:{"offset-y":"","bottom":"","nudge-left":"6px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pl-10",attrs:{"color":"accent-pine","icon":""}},'v-btn',attrs,false),on),[_vm._v("Downloads"),_c('v-icon',{staticClass:"ml-2",attrs:{"small":"","color":"accent-pine"}},[_vm._v("$dropdown")])],1)]}}],null,true)},[_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',_vm._l((item.downloads),function(download,i){return _c('v-hover',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:hover ? 'grey-light' : ''},[_c('v-list-item-content',[_c('DownloadButton',{staticClass:"d-flex px-0 accent-pine--text mt-1",attrs:{"url":download.url,"filename":download.name,"text":"","hide-icon":"","label":download.displayName},on:{"error":function () { return _vm.window.reload(); }}})],1)],1)]}}],null,true)})}),1)],1)],1):_vm._e()]}}],null,false,373399110)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }