




























































































































































































































































































































































































































































































import InfoCard from './InfoCard.vue'
import ReleaseStatistics from './ReleaseStatistics.vue'
import LotPreferenceForm from './forms/LotPreferenceForm.vue'
import DownloadButton from './inputs/DownloadButton.vue'
import BackButton from './BackButton.vue'
import ReleaseStatusChip from './ReleaseStatusChip.vue'
import ReleaseDistributionDetails from './ReleaseDistributionDetails.vue'
import { LotDTO, LotStatus } from '@/api-client'
import { Vue, Component, Prop } from 'vue-property-decorator'
import ReleaseStatus from '@/types/ReleaseStatus'
import { format, isAfter } from 'date-fns'
import { formatSigningTime, parseUtcDate } from '@/modules/date'
import { FinanceTimeframeDescription } from '@/types/FinanceTimeframe'
import { formatAUD } from '@/modules/stringUtils'
import { MARKETING_URL, PRODUCT_NAME } from '@/modules/config'
import { GetTimezoneFriendlyName } from '@/utils/timeZones'
import CompletedRelease from '@/types/CompletedRelease'

@Component({
  components: {
    ReleaseDistributionDetails,
    ReleaseStatusChip,
    BackButton,
    DownloadButton,
    LotPreferenceForm,
    ReleaseStatistics,
    InfoCard,
  },
  methods: {
    GetTimezoneFriendlyName,
  },
})
export default class ReleaseDetails extends Vue {
  @Prop({ required: true, type: Object }) release!: CompletedRelease
  ReleaseStatus = ReleaseStatus
  PRODUCT_NAME = PRODUCT_NAME
  MARKETING_URL = MARKETING_URL

  expandedPanel: -1 | 0 | 1 | 2 = -1

  get isDraft() {
    return ReleaseStatus.Draft === this.release.status
  }

  get skipAllocationPhase() {
    return this.release.details?.skipAllocationsPhase === true
  }

  get isReleaseOnlyRelease() {
    return (
      this.release.details?.skipAllocationsPhase === false &&
      this.release.details?.onDemandPurchasingEnabled === false
    )
  }

  get closeDate() {
    if (!this.release.details) return 'Not set'
    return format(
      parseUtcDate(this.release.details.applicationsCloseDate),
      'dd/LL/yyyy, hh:mm a',
    )
  }

  get allocationDate() {
    if (!this.release.details) return 'Not set'
    return format(
      parseUtcDate(this.release.details.allocationDate),
      'dd/LL/yyyy, hh:mm a',
    )
  }

  get isFutureApplicationDate() {
    if (!this.release.details) return true
    return isAfter(
      parseUtcDate(this.release.details.applicationsCloseDate),
      Date.now(),
    )
  }

  get isFutureAllocationDate() {
    if (!this.release.details) return true
    return isAfter(
      parseUtcDate(this.release.details.allocationDate),
      Date.now(),
    )
  }

  get formattedSigningTimes() {
    return this.release.signingTimes.map(time =>
      formatSigningTime(
        parseUtcDate(time.dateFrom!),
        parseUtcDate(time.dateTo!),
      ),
    )
  }

  get formattedDepositAmount() {
    return !this.release.signingDetails
      ? ''
      : formatAUD(this.release.signingDetails.depositAmount!, 1, true)
  }

  get legalPractitionerEmails() {
    if (!this.release.signingDetails) return []
    return this.release.signingDetails.legalPractitionerEmail
      .split(',')
      .filter(email => !!email)
  }

  get formattedFinanceTimeframe() {
    if (!this.release.signingDetails) return ''
    return FinanceTimeframeDescription[
      this.release.signingDetails.financeTimeframe
    ]
  }

  get canToggleAllocation() {
    return [
      ReleaseStatus.Draft,
      ReleaseStatus.Published,
      ReleaseStatus.Allocated,
      ReleaseStatus.OnDemandPurchasing,
    ].includes(this.release.status)
  }

  allocationToggled(lot: LotDTO) {
    if (this.release.lotStatusMap) {
      const { id, isRemovedFromSale } = lot
      const updatedStatus = isRemovedFromSale
        ? LotStatus.Unavailable
        : LotStatus.Available
      this.release.lotStatusMap = {
        ...this.release.lotStatusMap,
        [id!]: updatedStatus,
      }
    }
  }
}
