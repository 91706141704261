

























































































































import { StatisticAllocationDTO, AllocationType } from '@/api-client'
import AllocationStatus from '@/types/AllocationStatus'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify/types/index'
import DownloadButton from '@/components/inputs/DownloadButton.vue'

@Component({
  components: {
    DownloadButton,
  },
})
export default class ReleaseAllocationStatuses extends Vue {
  @Prop({ required: true, type: Array })
  allocations!: StatisticAllocationDTO[]

  prefColumnWidth = '106'
  headerClasses =
    'primary--text caption font-weight-bold grey-cultured-light table-header no-wrap py-6'
  expandedPanel = 0
  hasRetriedDownload = false

  AllocationType = AllocationType

  headers: DataTableHeader[] = [
    {
      text: 'Property',
      class: this.headerClasses,
      align: 'start',
      sortable: true,
      value: 'lot',
    },
    {
      text: 'Status',
      class: this.headerClasses,
      align: 'start',
      sortable: true,
      value: 'status',
    },
    {
      text: 'Contact details',
      class: this.headerClasses,
      align: 'start',
      sortable: true,
      value: 'contact',
    },
    {
      text: 'Particulars',
      class: this.headerClasses + ' centered-header',
      align: 'start',
      width: '90px',
      sortable: false,
      value: 'particularsComplete',
    },
    {
      text: 'Signing',
      class: this.headerClasses + ' centered-header ',
      align: 'start',
      width: '90px',
      sortable: false,
      value: 'signingTimeComplete',
    },
    {
      text: 'Deposit',
      class: this.headerClasses + ' centered-header',
      align: 'start',
      width: '90px',
      sortable: false,
      value: 'depositComplete',
    },
    {
      text: '',
      class: this.headerClasses,
      align: 'start',
      sortable: false,
      width: '148px',
      value: 'downloads',
    },
  ]

  get transformedAllocations() {
    return this.allocations.map(a => {
      let status =
        a.status === null ? 'Unallocated' : AllocationStatus[a.status!]
      if (a.allocationType == AllocationType.OnDemand) {
        status += ' (Buy Now)'
      }
      return {
        ...a,
        lot: a.lotName || 'N/A',
        status,
        fullName:
          a.givenName && a.familyName
            ? `${a.givenName} ${a.familyName}`
            : 'N/A',
      }
    })
  }
}
