











































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Papa from 'papaparse'
import * as Sentry from '@sentry/vue'
import { PRODUCT_NAME } from '@/modules/config'
import { LotDTO, ReleaseDTO } from '@/api-client'

import FileUpload from '@/components/inputs/FileUpload.vue'
import DownloadButton from '@/components/inputs/DownloadButton.vue'
import ValidationMessages from '@/components/ValidationMessages.vue'
import LotPreferenceForm from '@/components/forms/LotPreferenceForm.vue'
import { PropertyType } from '@/api-client'
import { validateLots, getCsvHeaders } from '@/utils/lotCsv'
import { chunk } from '@/utils/array'
import { csvHeadersToLotDTOMap, optionalFields } from '@/types/Csv'

@Component({
  components: {
    ValidationMessages,
    LotPreferenceForm,
    DownloadButton,
    FileUpload,
  },
})
export default class UploadLotDataForm extends Vue {
  PRODUCT_NAME = PRODUCT_NAME

  lotData: File | null = null
  csvErrors: string[] = []
  parsedLotData: LotDTO[] = []
  loading = false
  saveErrors: string[] = []
  templateFilename = ''
  url = ''

  @Prop({
    required: true,
    type: Object,
  })
  release!: ReleaseDTO

  get propertyType() {
    if (this.release.details?.propertyType) {
      return this.release.details.propertyType as PropertyType
    }
    return ''
  }

  @Watch('propertyType')
  onPropertyTypeInput() {
    this.url = `/${this.propertyType.toLowerCase()}Template.csv`
    this.templateFilename = `${this.propertyType}.csv`
    if (this.propertyType) {
      this.csvErrors = validateLots(this.propertyType, this.parsedLotData)
    }
  }

  async onLotDataInput(file: File | null) {
    this.loading = true
    if (file === null || !this.propertyType) {
      this.parsedLotData = []
      this.csvErrors = []
      this.loading = false
      return
    }

    const csvHeaders = getCsvHeaders(this.propertyType)

    await new Promise<Papa.ParseResult<Record<string, string>>>(
      (resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          // replace all line endings with \n to avoid excel / OS issues
          const result = (reader.result as string).replace(/[\r\n]+/g, '\n')
          Papa.parse(result, {
            header: true,
            dynamicTyping: false,
            skipEmptyLines: true,
            complete(results: Papa.ParseResult<Record<string, string>>) {
              resolve(results)
            },
            error(e: Papa.ParseError) {
              reject(e)
            },
          })
        }
        reader.onerror = e => {
          reject(e)
        }
        reader.readAsText(file)
      },
    )
      .then(res => {
        this.csvErrors = res.errors.map(e => `row ${e.row}: ${e.message}`)
        if (this.csvErrors.length === 0) {
          for (let i = 0; i < res.data.length; i++) {
            for (const key of csvHeaders) {
              if (!optionalFields.includes(key) && !res.data[i][key]) {
                this.csvErrors.push(`row ${i + 1}: missing ${key}`)
              }
              if (key === 'price') {
                if (!parseFloat(res.data[i][key])) {
                  this.csvErrors.push(`row ${i + 1}: price must be a number`)
                }
              }
            }
          }
        }
        if (!this.csvErrors.length) {
          this.parsedLotData = res.data.map(d => {
            const mapped = {}
            for (const key of Object.keys(csvHeadersToLotDTOMap)) {
              if (d[key] && !(key in mapped)) {
                Object.assign(mapped, {
                  [csvHeadersToLotDTOMap[key]]: d[key],
                })
              }
            }
            for (const value of Object.values(csvHeadersToLotDTOMap)) {
              if (!(value in mapped)) {
                Object.assign(mapped, { [value]: '' })
              }
            }
            return mapped as LotDTO
          })
        }
      })
      .catch(e => {
        if (file !== null) this.csvErrors = [e.message]
        Sentry.captureException(e)
      })
      .finally(() => {
        this.loading = false
      })
  }

  async saveLotData() {
    this.loading = true
    this.saveErrors = []

    try {
      // Notify the API we're starting the lots upload
      await this.$api.release.v1ReleasesReleaseIdLotsStartPost(
        this.release.id,
        {
          fileName: this.lotData!.name,
        },
      )

      // We'll await them in order, so lots are uploaded in the order they were in the file
      for (const chunkOfLots of chunk(this.parsedLotData, 10)) {
        await this.$api.release.v1ReleasesReleaseIdLotsPost(this.release.id, {
          lots: chunkOfLots,
        })
      }

      this.$emit('input', {
        lotsImportFileName: this.lotData!.name,
        lotsImportDate: new Date().toUTCString(),
        lots: this.parsedLotData,
      } as Partial<ReleaseDTO>)
    } catch {
      this.saveErrors = ['An error occurred.']
    }
    this.loading = false
  }

  mounted() {
    // TODO: show original upload time
    if (this.release.lotsImportFileName) {
      this.lotData = new File([], this.release.lotsImportFileName!)
    }
    this.parsedLotData = this.release.lots ?? []
    this.onPropertyTypeInput()
  }
}
